.slide-tabs{
    list-style-type: none;
    cursor: pointer;
    padding: 0rem 1.5rem;
}
.slide-tabs{
    display: flex;
    align-items: center;
}

*{
    margin: 0;
    padding: 0;
    /* box-sizing: border-box; */
}
     
.modal-wrapper{
    position: fixed;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(189,189,189,0.9);
    
}
.modal-container{
    position: fixed;
    top: 50%;
    z-index: 20;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50rem;
}